html, body {
  height: 100%;
  margin: 0;
}

#root, .App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Ensure content takes up flexible space and pushes footer down */
.App > .content {
  flex: 1;
}
