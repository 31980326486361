.features-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
  }
  
  .features-container h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #0f9d75;
    font-family: 'Space Mono', monospace;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .feature-item {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .feature-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .feature-item h3 {
    color: #0f9d75;
    margin-bottom: 1rem;
    font-family: 'Space Mono', monospace;
  }
  
  .feature-item p {
    font-size: 1rem;
    color: #333;
    font-family: 'Space Mono', monospace;
  }

  /* Existing Styles */

@media (max-width: 768px) {
    .features-container {
      padding: 20px 20px; /* Reduce padding for smaller screens */
    }
  
    .features-container h2 {
      font-size: 3.5rem; /* Slightly reduce the font size for smaller screens */
      margin-bottom: 1.5rem; /* Adjust spacing to be proportionate on smaller devices */
    }
  
    .features-grid {
      gap: 15px; /* Reduce gap to utilize space more efficiently on smaller screens */
    }
  
    .feature-item {
      padding: 15px; /* Slightly reduce padding to increase available space for content */
    }
  
    .feature-item h3 {
      font-size: 1.25rem; /* Adjust headline font size for mobile readability */
    }
  
    .feature-item p {
      font-size: 0.9rem; /* Adjust paragraph font size for mobile readability */
    }
  }
  
  