.consultation-cta-container {
    background-color: #0f9d75; /* Use a color that stands out */
    color: #ffffff; /* Ensure text is readable against the background */
    text-align: center;
    padding: 40px 0px;
    margin: 0px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow for depth */
  }
  
  .consultation-cta-container h2 {
    margin-bottom: 20px;
    font-family: 'Space Mono', monospace;
    color: #ffffff;
  }
  
  .consultation-cta-container p {
    margin-bottom: 30px;
    font-family: 'Space Mono', monospace;
    color: #ffffff;
  }
  
  .book-consultation-btn {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 15px 30px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    text-transform: uppercase; /* Optional: for emphasis */
    font-family: 'Space Mono', monospace; /* Consistent typography */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Optional: more pronounced shadow for depth */
  }
  
  .book-consultation-btn:hover {
    background-color: #007bff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .book-consultation-btn:active {
    transform: translateY(2px); /* Subtle press effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  