/* NavBar.css */
.navbar {
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 0 20px;
    display: flex;
    justify-content: space-between; /* Keeps elements spaced out */
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 80px; /* Adjusted height */
}

.links-container {
    display: flex;
    justify-content: flex-end; /* Align items to the end on desktop */
    align-items: center;
    flex-grow: 1;
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 50px; /* Adjusted gap between links */
    align-items: center;
    padding: 0;
    margin: 0; /* Ensure the navigation links hug the right without extra space */
}

.nav-links li {
    transition: all 0.3s ease;
}

.nav-links li:hover {
    transform: scale(1.1);
}

.nav-links a, .nav-links button {
    font-family: 'Space Mono', monospace;
    text-decoration: none;
    color: #333;
    font-weight: 500;
}

.nav-links button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s ease;
}

.nav-links button:hover {
    background-color: #0056b3;
}

.menu-text {
    display: none; /* Remain hidden on desktop, visible on mobile */
}
  
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      padding: 10px 0px;
      height: auto;
    }
  
    .menu-text {
      display: block;
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      color: #007bff;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 10px 0; /* Added margin for spacing */
    }
  
    .links-container {
      width: 100%;
    }
  
    .nav-links {
      display: none;
      flex-direction: column;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  
    .nav-links.open {
      display: flex;
    }
  
    .nav-links li, .nav-links a, .nav-links button {
      width: 100%;
      padding: 20px 10px;
      text-align: center;
    }
  
    .nav-links a:hover, .nav-links button:hover {
      background-color: #f0f0f0;
    }
  }

  @media (min-width: 769px) {
    .links-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 1;
      padding-right: 20px; /* Adds padding on the right for desktop only */
    }
  
    /* If you prefer to adjust the .nav-links directly */
    .nav-links {
      list-style: none;
      display: flex;
      gap: 50px;
      align-items: center;
      padding: 0;
      margin: 0;
      margin-right: 20px; /* Adds margin on the right to prevent hugging the edge on desktop only */
    }
  }
  