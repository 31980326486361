html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.site-footer {
  background-color: #0f9d75;
  color: white;
  text-align: center;
  padding: 20px 0;
  width: 100%;
  margin-top: auto; /* Pushes the footer to the bottom */
}


.footer-content {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-content p {
  margin: 0;
  font-family: 'Space Mono', monospace;
  color: #000000;
}

.social-links a {
  color: rgb(0, 0, 0);
  margin: 0 10px;
  font-size: 24px; /* Adjust based on your design preference */
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #eaeaea; /* Lighter shade for hover effect */
}
