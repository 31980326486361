.toolsContainer {
  padding: 40px;
  background-color: #ffffff;
}

.toolsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 40px;
  justify-content: center;
}

.toolItem {
  position: relative;
  width: 100%;
  height: 240px;
  transform-style: preserve-3d;
  transition: transform 0.8s;
  cursor: pointer;
  font-family: 'Space Mono', monospace;
  perspective: 1000px;
}

.toolItem:hover {
  transform: rotateY(180deg);
}

.toolFace {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

.toolFront {
  background-color: #ffffff;
  border: 2px solid #007bff;
}

.toolBack {
  background-color: #007bff;
  color: white;
  transform: rotateY(180deg);
}

.toolIcon {
  font-size: 64px;
  margin-bottom: 20px;
  color: #007bff;
  transition: transform 0.3s ease-in-out;
}

.toolItem:hover .toolIcon {
  transform: scale(1.2);
}

.toolName {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.toolDescription {
  font-size: 16px;
}

.toolsTitle {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
  color: #007bff;
  font-family: 'Space Mono', monospace;
}

@media (max-width: 768px) {
  .toolsContainer {
    padding: 20px;
  }

  .toolsTitle {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .toolsGrid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
  }

  .toolItem {
    height: 200px;
  }

  .toolIcon {
    font-size: 48px;
    margin-bottom: 10px;
  }

  .toolName {
    font-size: 20px;
  }

  .toolDescription {
    font-size: 14px;
  }
}