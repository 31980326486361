.contact-container {
  max-width: 900px;
  margin: 150px auto 40px;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 20px;
  position: relative;
  z-index: 2;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* Form Styles */
.contact-form {
  width: 50%;
  text-align: left;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-form input,
.contact-form textarea {
  background-color: transparent;
  color: #333;
  border: none;
  border-bottom: 2px solid #007bff;
  padding: 15px 10px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-bottom-color: #00b347;
  box-shadow: 0 0 10px rgba(0, 179, 71, 0.4);
}

/* Button Styles */
.contact-form button {
  padding: 15px 30px;
  font-size: 16px;
  letter-spacing: 1px;
  border-radius: 30px;
  background-color: #007bff;
  color: #fff;
  font-family: 'Space Mono', monospace;
  cursor: pointer;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  overflow: hidden;
  align-self: flex-start;
}

.contact-form button:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transition: width 0.5s ease, height 0.5s ease;
}

.contact-form button:hover:before {
  width: 200%;
  height: 200%;
}

.contact-form button:active {
  transform: translateY(1px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

/* Success Message Styles */
.submission-success {
  color: #28a745;
  font-size: 1.2rem;
  padding: 1.5rem 2rem;
  margin: 2rem auto;
  margin-top: 300px;
  border-radius: 8px;
  background-color: rgba(240, 249, 240, 0.9);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  font-family: 'Space Mono', monospace;
  text-align: center;
  max-width: 600px;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  animation: fadeInUp 0.6s ease;
  position: relative;
  z-index: 1;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Social Links and Talk to Us Styles */
.contact-details {
  width: 40%;
  text-align: left;
  font-family: 'Space Mono', monospace;
}

.social-links a {
  color: #000000; /* Always visible in black */
  margin: 0 10px;
  font-size: 24px;
  font-family: 'Space Mono', monospace;
}

.social-links a:hover {
  color: #00b347; /* Change to green on hover */
  transform: scale(1.2);
}

.talk-to-us a {
  color: #333;
  margin: 0 10px;
  font-size: 24px;
  transition: color 0.3s, transform 0.3s;
  font-family: 'Space Mono', monospace;
}

.talk-to-us a:hover {
  color: #007bff;
  transform: scale(1.2);
}


.talk-to-us {
  margin-top: 40px;
  color: #000000;
  font-family: 'Space Mono', monospace;
}

.talk-to-us p {
  margin: 10px 0;
  color: #333;
  font-family: 'Space Mono', monospace;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
  }

  .contact-form,
  .contact-details {
    width: 100%;
    text-align: center;
  }

  .contact-form button {
    align-self: center;
  }


  .submission-success {
    font-size: 1rem;
    padding: 1.2rem 1.5rem;
    margin: 1.5rem auto;
    margin-top: 250px;
    border-radius: 6px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
    max-width: 90%;
  }
}