.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    margin-top: 100px;
  }
  
  .hero-section h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Space Mono', monospace;
  }
  
  .hero-section p {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .history-section, .mission-section, .values-section {
    margin-bottom: 40px;
  }
  
  h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #0f9d75;
    font-family: 'Space Mono', monospace;
  }
  
  p, li {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
    font-family: 'Space Mono', monospace;
  }
  
  .values-section ul {
    list-style-type: none; /* Removes default list styling */
    padding: 0;
  }
  
  .values-section li {
    background-color: #f9f9f9;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  