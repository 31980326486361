/* Global Styles */
.services-container {
    text-align: center;
    margin-top: 100px;
  }
  
  .services-header h1, .services-header .hover-instruction {
    font-family: 'Space Mono', monospace;
  }
  
  .hover-instruction {
    display: none; /* Initially hidden, will be shown only on desktop */
    color: #666; /* Subtle color */
    font-size: 0.9rem; /* Smaller font size for the instruction text */
  }
  
/* Mobile Styles */
@media (max-width: 768px) {
    .service-box p {
      display: block; 
      font-family: 'Space Mono', monospace;
      color: #666;
    }

    .services-header h1, .services-header .hover-instruction {
        font-family: 'Space Mono', monospace;
        margin-top: 150px;
        margin-bottom: -50px;
      }
  }
  
  /* Desktop Styles */
  @media (min-width: 769px) {
    .services-header h1,
    .hover-instruction {
      display: block; /* Show these elements on desktop */
      font-family: 'Space Mono', monospace;
    }
  
    .hover-instruction {
      font-size: 0.9rem;
      margin-top: -10px; /* Adjust spacing as needed */
      color: #666;
      font-family: 'Space Mono', monospace;
    }
  
    .service-box p {
      display: none; /* Hide description by default on desktop */
      font-family: 'Space Mono', monospace;
    }
  
    .service-box:hover p {
      display: block; /* Show description on hover */
      font-family: 'Space Mono', monospace;
    }
  }




.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    text-align: center;
    margin-top: 100px;
  }
  
  .service-box {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    overflow: hidden;
  }
  
  .service-box h2 {
    margin: 0;
    font-family: 'Space Mono', monospace;
    color: #333;
  }
  
  .service-box p {
    font-family: 'Space Mono', monospace;
    color: #666;
    display: none; /* Hide description by default */
    margin-top: 20px; /* Space between title and description */
  }
  
  .service-box.active p, .service-box:hover p {
    display: block; /* Show description on hover or when active */
  }
  