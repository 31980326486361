/* IntroBox.css */
.intro-box {
    background-color: #0f9d75;
    text-align: center;
    padding: 0px, 0px;
    border-radius: 8px;
    margin: 200px auto;
    max-width: 600px;
  }
  

  .brand-name {
    font-family: 'Space Mono', monospace; /* Apply the Space Mono font */
    font-weight: 700; /* Choose your desired weight, e.g., bold */
    font-size: 24px; /* Adjust the size as needed */
  }
  
  
  .intro-box p {
    color: #666;
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .consultation-button {
    background-color: #007bff;
    color: white;
    padding: 10px 25px;
    text-decoration: none;
    border-radius: 10px;
    font-weight: bold;
    transition: background-color 0.3s;
    font-family: 'Space Mono', monospace;
  }
  
  .consultation-button:hover {
    background-color: #0056b3;
  }
  
  .intro-logo {
    max-width: 500px;
    margin: 0 auto;
    display: block;
    animation: spin 20s linear infinite; /* Apply the spinning animation */
  }
  
  /* Keyframes for spinning animation */
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @media (max-width: 768px) {
    .intro-box {
      padding: 0; /* Ensure padding does not cause overflow */
      margin: 0px 40px; /* Add horizontal margin to prevent content from touching the screen edges */
      margin-top: 140px;
    }
  
    .intro-logo {
      max-width: 300px; /* Adjust logo size for smaller screens to prevent overflow */
    }
  }
  